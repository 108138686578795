import { ConfirmationModal } from "best-common-react";
import { Location } from "history";
import React, { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

interface RouteLeavingGuardProps {
  when?: boolean | undefined;
}

const RouteLeavingGuard = ({ when }: RouteLeavingGuardProps) => {
  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationModal
        show={modalVisible}
        header="Unsaved Changes"
        headerClose={true}
        body="You have unsaved changes. Are you sure you want to leave this page without saving?"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={handleConfirmNavigationClick}
        onCancel={closeModal}
      />
    </>
  );
};
export default RouteLeavingGuard;
