import {
  faChevronDown,
  faChevronUp,
  faCirclePlus,
  faGear,
  faSquare,
  faSquareCheck,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileView } from "best-common-react";
import React from "react";
import styled from "styled-components";

const ListRowStyledComponent = styled.div.attrs({
  className: "list-row",
})`
  // height: 4.25rem;
  background-color: ${(props) => props.theme.colors["section-bg"]};
  margin-bottom: 3px;
  border-radius: 4px;
  align-items: center;
  color: white;
  font-size: 1rem;
`;

const ListRowMobileHeader = styled.div`
  width: 100%;
  background-color: #3b5d94;
  padding: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const ListRowBody = styled.div.attrs({
  className: "list-row-body row",
})`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1.5rem;
`;

interface ListRowProps {
  children: React.ReactNode;
  header?: React.ReactNode;
}

export const ListRow: React.FC<ListRowProps> = ({ children, header }) => {
  return (
    <ListRowStyledComponent>
      {header ? (
        <MobileView>
          <ListRowMobileHeader>{header}</ListRowMobileHeader>
        </MobileView>
      ) : null}

      <ListRowBody>{children}</ListRowBody>
    </ListRowStyledComponent>
  );
};

export const PointerIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  :active {
    color: #d0d5d7;
  }
`;

interface TrashcanButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
}

export const TrashcanButton: React.FC<TrashcanButtonProps> = ({ onClick }) => {
  return <PointerIcon icon={faTrashCan} fontSize="1.3rem" onClick={onClick} />;
};

export const TrashcanWrapper = styled.div.attrs({
  className: "col-xl-1",
})`
  text-align: right;
`;

interface AddButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  size?: string;
}

export const AddButton: React.FC<AddButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faCirclePlus} fontSize={size || "1.3rem"} onClick={onClick} />;
};

interface ArrowUpButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  size?: string;
}
export const ArrowUpButton: React.FC<ArrowUpButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faChevronUp} fontSize={size || "1.3rem"} onClick={onClick} />;
};
export const ArrowDownButton: React.FC<ArrowUpButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faChevronDown} fontSize={size || "1.3rem"} onClick={onClick} />;
};

interface GearButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  size?: string;
}
export const GearButton: React.FC<GearButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faGear} fontSize={size || "1.3rem"} onClick={onClick} />;
};

interface SquareCheckButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  size?: string;
}
export const SquareCheckButton: React.FC<SquareCheckButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faSquareCheck} fontSize={size || "1.3rem"} onClick={onClick} />;
};

interface SquareButtonProps {
  onClick?: (event?: React.MouseEvent<SVGSVGElement>) => void;
  size?: string;
}
export const SquareButton: React.FC<SquareCheckButtonProps> = ({ onClick, size }) => {
  return <PointerIcon icon={faSquare} fontSize={size || "1.3rem"} onClick={onClick} />;
};

export const HeaderRow = styled.div.attrs({
  className: "header-row",
})`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
`;

export const ListCounter = styled.span`
  font-size: 0.688rem;
  color: ${(props) => props.theme.colors["light-blue-grey"]};
`;

export const TextButton = styled.button.attrs({})`
  font-size: 0.875rem;
  font-weight: bold;
  font-style: italic;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;

  border: none;
  background-color: inherit;
  display: inline-block;
  padding: 0;

  :active {
    color: #d0d5d7;
  }
`;
