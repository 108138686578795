export const RouteConstants = {
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login/callback",
  BASE: "/",
  HOME: "/home",
  BALLPARKS: "/ballparks",
  BALLPARK: "/ballpark/:ballparkId",
  BALLPARK_TARGET_CONFIG: "/ballpark/:ballparkName/:ballparkId/targetConfig",
  USERS: "/users",
  SETTINGS: "/settings",
  EVENTS: "/settings/events",
  CONDITIONS: "/settings/conditions",
  EVENTS_DEFAULT: "/settings/eventDefaults",
  TARGETS: "/settings/targets",
  TARGET_CONFIG_DEFAULTS: "/settings/targetConfigDefaults",
  ACTIONS: "/settings/actions",
  ACTION_TARGET_GROUPS: "/settings/actionTargetGroups",
};
