import { UserInfo } from "../contexts/UserContext";
import { Action } from "../types/Action";
import { ActionTargetGroup } from "../types/ActionTargetGroup";
import { ActionTargetGroupStatus } from "../types/ActionTargetGroupStatus";
import { Condition } from "../types/Condition";
import { ConfigField } from "../types/ConfigField";
import { EventWithDefaultConditions } from "../types/EventWithDefaultConditions";
import { Role } from "../types/Role";
import { Target } from "../types/Target";
import { TargetActionConfigTemplate, VenueActionConfig } from "../types/TargetActionConfigTemplate";
import { VenueEvent } from "../types/VenueEvent";
import { fieldSenseConfigV1Client } from "../utils/axios";

export const getUserInfo = async (): Promise<UserInfo> => {
  const { data } = await fieldSenseConfigV1Client.get(`/user/userInfo`);
  return data;
};

export const getAllUserInfo = async (): Promise<UserInfo[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/user/userInfo/all`);
  return data.users;
};

export const getActions = async (): Promise<Action[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/actions`);
  return data.actions;
};

export const saveActions = async (actions: Action[]): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/actions`, actions);
};

export const getTargets = async (): Promise<Target[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/targets`);
  return data.targets;
};

export const saveTargets = async (targets: Target[]): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/targets`, targets);
};

export const getVenueActionConfigTypes = async (): Promise<string[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/venueActionConfigTypes`);
  return data.venueActionConfigTypes;
};

export const getConditions = async (): Promise<Condition[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/conditions`);
  return data.conditions;
};

export const saveConditions = async (conditions: Condition[]): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/conditions`, conditions);
};

export const getOperators = async (): Promise<string[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/operators`);
  return data.operators;
};

export const getEventsWithDefaultConditions = async (): Promise<EventWithDefaultConditions[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/events`);
  return data.events;
};

export const saveEventsWithDefaultConditions = async (events: EventWithDefaultConditions[]): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/events`, events);
};

export const getTargetActionConfigTemplate = async (targetId: number): Promise<TargetActionConfigTemplate> => {
  const { data } = await fieldSenseConfigV1Client.get(`/actionConfigTemplates/${targetId}`);
  return data;
};

export const saveTargetActionConfigTemplate = async (
  targetActionConfigTemplate: TargetActionConfigTemplate,
): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/actionConfigTemplates`, targetActionConfigTemplate);
};

export const getConfigFields = async (): Promise<ConfigField[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/configFields`);
  return data.configFields;
};

export const getConfigFieldsForConfigType = async (configType: string): Promise<ConfigField[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/configFields/${configType}`);
  return data.configFields;
};

export const getVenueEvents = async (venueId: number): Promise<VenueEvent[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/events/venue/${venueId}`);
  return data.venueEvents;
};

export const saveVenueEvents = async (venueEvents: VenueEvent[], venueId: number): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/events/venue/${venueId}`, venueEvents);
};

export const getTargetConfig = async (
  venueId: number,
  actionId: number,
  targetId: number,
): Promise<VenueActionConfig> => {
  const { data } = await fieldSenseConfigV1Client.get(
    `/events/venue/${venueId}/action/${actionId}/target/${targetId}/config`,
  );
  return data;
};

export const bustCache = async (venueId: number, payload: { triCode: string; league: string }): Promise<void> => {
  await fieldSenseConfigV1Client.delete(`/config/${venueId}/cache/events`, { data: payload });
};

export const getRoles = async (): Promise<Role[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/roles`);
  return data.roles;
};

export const saveUsers = async (users: UserInfo[]): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/user/userInfo`, users);
};

export const importUsers = async (): Promise<void> => {
  await fieldSenseConfigV1Client.post(`/user/userInfo/import`);
};

export const runVenueEventTest = async (
  venueId: number,
  venueEventId: number,
  payload: { triCode: string; league: string },
): Promise<void> => {
  await fieldSenseConfigV1Client.put(`/events/venue/${venueId}/test/${venueEventId}`, payload);
};

export const autoConfigureBallparkHaptics = async (payload: {
  type: string;
  active: boolean;
  triCode: string;
  league: string;
}): Promise<void> => {
  await fieldSenseConfigV1Client.put(`/config/autoconfigure/haptics`, payload);
};

export const getActionTargetGroups = async (): Promise<ActionTargetGroup[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/action-target-groups`);
  return data.actionTargetGroups;
};

export const saveActionTargetGroups = async (payload: { actionTargetGroups: ActionTargetGroup[] }): Promise<void> => {
  await fieldSenseConfigV1Client.put(`/action-target-groups`, payload);
};

export const getActionTargetGroupStatuses = async (venueId: number): Promise<ActionTargetGroupStatus[]> => {
  const { data } = await fieldSenseConfigV1Client.get(`/action-target-groups/venue/${venueId}/statuses`);
  return data.statuses;
};

export const saveActionTargetGroupStatuses = async (
  venueId: number,
  payload: {
    statuses: ActionTargetGroupStatus[];
  },
): Promise<void> => {
  await fieldSenseConfigV1Client.put(`/action-target-groups/venue/${venueId}/statuses`, payload);
};
