import React, { createContext, useContext, useEffect, useState } from "react";
import { RoleConstants } from "../constants/RoleConstants";
import { Role } from "../types/Role";
import { useUser } from "./UserContext";

type PermissionsContextType = {
  hasBallpark: (venueId: number) => boolean;
  hasMultipleBallparks: () => boolean;
  hasAnyBallparks: () => boolean;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isAdminOrHigher: boolean;
  isTech: boolean;
};

const PermissionsContext = createContext<PermissionsContextType | undefined>(undefined);

const PermissionsProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { userInfo } = useUser();
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isAdminOrHigher, setIsAdminOrHigher] = useState<boolean>(false);
  const [isTech, setIsTech] = useState<boolean>(false);

  const checkForRole = (role: Role) => {
    setIsSuperAdmin(role.role === RoleConstants.SUPER_ADMIN);
    setIsAdmin(role.role === RoleConstants.ADMIN);
    setIsAdminOrHigher(role.role === RoleConstants.SUPER_ADMIN || role.role === RoleConstants.ADMIN);
    setIsTech(role.role === RoleConstants.TECH);
  };

  const hasBallpark = (venueId: number): boolean => {
    return isAdminOrHigher || !!userInfo?.ballparks.filter((venue) => venue.id === venueId).length;
  };

  const hasMultipleBallparks = (): boolean => {
    return isAdminOrHigher || userInfo?.ballparks.length > 1;
  };

  const hasAnyBallparks = (): boolean => {
    return isAdminOrHigher || userInfo?.ballparks.length > 0;
  };

  useEffect(() => {
    if (!!userInfo) {
      checkForRole(userInfo.roles[0]);
    }
  }, [userInfo]);

  return (
    <PermissionsContext.Provider
      value={{
        hasBallpark,
        hasMultipleBallparks,
        hasAnyBallparks,
        isSuperAdmin,
        isAdmin,
        isAdminOrHigher,
        isTech,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

const usePermissions = (): PermissionsContextType => {
  const permissionsContext: PermissionsContextType = useContext<PermissionsContextType>(PermissionsContext);
  if (permissionsContext === undefined) {
    throw new Error(`usePermissions must be used within a PermissionsProvider`);
  }
  return permissionsContext;
};

export { PermissionsContext, PermissionsProvider, usePermissions };
