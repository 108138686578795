import React, { Suspense } from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Alerts from "./components/elements/Alerts";
import { BallparkProvider } from "./contexts/BallparkContext";
import { FormProvider } from "./contexts/FormContext";
import WrapperProvider from "./modules/WrapperProvider";

ReactDom.render(
  <WrapperProvider>
    <Suspense fallback={<h1>Loading...</h1>}>
      <Alerts />
      <BrowserRouter>
        <FormProvider>
          <BallparkProvider>
            <App />
          </BallparkProvider>
        </FormProvider>
      </BrowserRouter>
    </Suspense>
  </WrapperProvider>,
  document.getElementById("frontend-starter-app"),
);
