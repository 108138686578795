import {
  DesktopTabletView,
  MobileView,
  NavigationConfigType,
  NavigationFromConfig,
  VerticalNavigationContainer,
} from "best-common-react";
import React, { lazy, useMemo } from "react";
import styled from "styled-components";
import "./App.css";
import logo from "./assets/logo.png";
import RouteLeavingGuard from "./components/nav/RouteLeavingGuard";
import TopBarRow from "./components/nav/TopBarRow";
import { RouteConstants } from "./constants/RouteConstants";
import { useForm } from "./contexts/FormContext";
import { usePermissions } from "./contexts/PermissionsContext";
import { useUser } from "./contexts/UserContext";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

const AppWrapper = styled.div.attrs({
  className: "app-wrapper",
})`
  padding-left: 1.75rem;
  padding-right: 1.75rem;
`;

const MobileAppWrapper = styled.div`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 2.813rem;
`;

const App: React.FC = () => {
  const { loggedIn, logout, userInfo } = useUser();
  const { isDirty } = useForm();
  const { isSuperAdmin, isAdminOrHigher, isTech, hasMultipleBallparks } = usePermissions();

  const navConfig: NavigationConfigType = useMemo(
    () => ({
      env: "dev",
      title: "",
      loggedIn: loggedIn,
      username: userInfo?.email,
      displayEnvIndicator: false,
      displayPermissionsIndicator: true,
      // permissions: userInfo?.role.label,
      // startLogo: {
      //     logo: logo,
      // },
      centerLogo: {
        logo: logo,
        visible: true,
      },
      usernameDropdownLinks: [
        {
          name: "Sign Out",
          onClick: logout,
        },
      ],
      navigationLinks: [
        {
          name: "Ballparks",
          icon: null,
          to: RouteConstants.BALLPARKS,
          visible: isAdminOrHigher || (isTech && hasMultipleBallparks()),
        },
        { name: "Users", icon: null, to: RouteConstants.USERS, visible: isSuperAdmin },
        {
          name: "Settings",
          icon: null,
          to: RouteConstants.SETTINGS,
          visible: isAdminOrHigher,
          subLinks: [
            {
              name: "Events",
              icon: null,
              to: RouteConstants.EVENTS,
              visible: true,
            },
            {
              name: "Event Defaults",
              icon: null,
              to: RouteConstants.EVENTS_DEFAULT,
              visible: true,
            },
            {
              name: "Conditions",
              icon: null,
              to: RouteConstants.CONDITIONS,
              visible: true,
            },
            {
              name: "Targets",
              icon: null,
              to: RouteConstants.TARGETS,
              visible: true,
            },
            {
              name: "Target Config Defaults",
              icon: null,
              to: RouteConstants.TARGET_CONFIG_DEFAULTS,
              visible: true,
            },
            {
              name: "Actions",
              icon: null,
              to: RouteConstants.ACTIONS,
              visible: true,
            },
            {
              name: "Action Target Groups",
              icon: null,
              to: RouteConstants.ACTION_TARGET_GROUPS,
              visible: true,
            },
          ],
        },
      ],
      tabletSupport: false,
      topBarComponent: <TopBarRow />,
    }),
    [loggedIn, userInfo, logout, isSuperAdmin, isAdminOrHigher, isTech, hasMultipleBallparks],
  );

  return (
    <div>
      <RouteLeavingGuard when={isDirty} />
      {loggedIn ? (
        <div>
          <div className="logo-wrapper">
            <div className="admin-wrapper">
              <span className="admin-text">Admin</span>
            </div>
            <img className="logo" src={logo} />
          </div>
          <div className="top-nav-wrapper">
            <NavigationFromConfig vertical={true} config={navConfig} />
          </div>

          <VerticalNavigationContainer loggedIn={true}>
            <MobileView>
              <TopBarRow />
            </MobileView>
            <MobileView>
              <MobileAppWrapper>
                <AuthenticatedApp />
              </MobileAppWrapper>
            </MobileView>
            <DesktopTabletView>
              <AppWrapper>
                <AuthenticatedApp />
              </AppWrapper>
            </DesktopTabletView>
          </VerticalNavigationContainer>
        </div>
      ) : (
        <UnauthenticatedApp />
      )}
    </div>
  );
};

export default App;
