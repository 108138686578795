import { ColorType, ThemeType, Traditional, TraditionalColors } from "best-common-react";

declare type FieldSenseColorType = ColorType & {
  navy: string;
  error: string;
  danger: string;
  success: string;
  placeholder: string;
  "light-blue-grey": string;
  "ice-blue": string;
  "btn-disabled": string;
  "toggle-not-selected": string;
  "section-bg": string;
};

export const FieldSenseColors: FieldSenseColorType = {
  // Default BCR Colors
  ...TraditionalColors,
  // Application Specific Colors
  navy: "#031e42",
  error: "#fc250c",
  danger: "#ff0147",
  success: "#00ff76",
  placeholder: "#446fa7",
  "light-blue-grey": "#b8cde6",
  "ice-blue": "#ebf3f9",
  "btn-disabled": "#011f4f",
  "toggle-not-selected": "#002664",
  "section-bg": "#1a4181",
};

const FontWeights = {
  thin: "100",
  light: "300",
  normal: "400",
  bold: "500",
};

const FieldSenseTheme: ThemeType = {
  ...Traditional,
  name: "FieldSenseTheme",
  fontFamily: '"Roboto", sans-serif',
  colors: FieldSenseColors,
  background: FieldSenseColors["mlb-blue"],
  // Add component style overrides below.
  // See BCR themes source for examples
  input: {
    color: TraditionalColors.white,
    height: "2.25rem",
    "border-color": FieldSenseColors.navy,
    size: "0.875rem",
    background: FieldSenseColors["toggle-not-selected"],
    missed: {
      color: TraditionalColors["mlb-red"],
      background: TraditionalColors["white"],
      "border-color": TraditionalColors["mlb-red"],
    },
    required: {
      color: TraditionalColors["mlb-blue"],
      background: TraditionalColors["white"],
      "border-color": TraditionalColors["mlb-blue"],
    },
    disabled: {
      color: TraditionalColors["black"],
      background: TraditionalColors["almost-white"],
      "border-color": TraditionalColors["pale-blue"],
    },
  },
  select: {
    placeholder: {
      fontWeight: FontWeights.light,
      color: FieldSenseColors.placeholder,
      fontStyle: "italic",
    },
    hover: {
      color: TraditionalColors.white,
      background: TraditionalColors.blue,
    },
    background: FieldSenseColors["toggle-not-selected"],
    borderColor: FieldSenseColors.navy,
    color: TraditionalColors.white,
    multiBackground: TraditionalColors["almost-white"],
    multiBorderRadius: "2px",
    actionColor: TraditionalColors.white,
    required: {
      color: TraditionalColors["mlb-blue"],
      background: TraditionalColors["white"],
      borderColor: TraditionalColors["mlb-blue"],
    },
    disabled: {
      background: TraditionalColors["light-grey"],
    },
    clearIndicator: {
      padding: "8px",
    },
  },
  toggleButtons: {
    background: FieldSenseColors["toggle-not-selected"],
    border: `1px solid ${FieldSenseColors.navy}`,
    buttonBackgroundColor: FieldSenseColors["toggle-not-selected"],
    buttonActiveColor: TraditionalColors.blue,
    height: "2.25rem",
    buttonLineHeight: "0.75rem",
  },
  button: {
    ...Traditional.button,
    "border-radius": "4px",
    primary: {
      "background-color": FieldSenseColors["light-blue-grey"],
      "border-color": TraditionalColors.white,
      color: FieldSenseColors["mlb-navy"],
      hover: FieldSenseColors["mlb-red"],
    },
    secondary: {
      "background-color": "#234b8d",
      "border-color": "#7a93b1",
      color: FieldSenseColors["light-blue-grey"],
      hover: FieldSenseColors["light-blue-grey"],
    },
    disabled: {
      "background-color": FieldSenseColors["btn-disabled"],
      "border-color": FieldSenseColors["btn-disabled"],
      color: "#2b4a70",
      hover: FieldSenseColors["background-blue"],
    },
  },
  label: {
    ...Traditional.label,
    color: TraditionalColors.white,
    "asterisk-color": FieldSenseColors.error,
    "font-weight": FontWeights.normal,
    size: "1rem",
  },
  placeholder: {
    color: FieldSenseColors.placeholder,
    "font-style": "italic",
    "font-weight": FontWeights.light,
  },
  alert: {
    ...Traditional.alert,
    success: {
      background: FieldSenseColors.success,
      color: FieldSenseColors.navy,
      border: TraditionalColors.black,
    },
    danger: {
      background: FieldSenseColors.danger,
      color: FieldSenseColors.navy,
      border: TraditionalColors.black,
    },
  },
  modal: {
    ...Traditional.modal,
    borderColor: FieldSenseColors.navy,
    backdrop: FieldSenseColors.navy,
    backdropOpacity: 0.75,
    header: {
      ...Traditional.modal.header,
      background: FieldSenseColors["section-bg"],
      bottomBorderColor: FieldSenseColors.navy,
      closeIcon: "fa-times",
      fontColor: FieldSenseColors.white,
      fontFamily: "Roboto",
      fontSize: "24px",
      fontWeight: FontWeights.bold,
    },
    body: {
      ...Traditional.modal.body,
      background: FieldSenseColors["mlb-blue"],
      fontColor: FieldSenseColors["light-blue-grey"],
    },
    footer: {
      ...Traditional.modal.footer,
      background: FieldSenseColors["section-bg"],
      borderTopColor: FieldSenseColors.navy,
    },
  },
  checkbox: {
    ...Traditional.checkbox,
    color: FieldSenseColors.white,
    labelColor: FieldSenseColors.white,
    background: FieldSenseColors["toggle-not-selected"],
    borderColor: FieldSenseColors.navy,
    margin: "0 0 0 0.5rem",
  },
  navigation: {
    vertical: {
      ...Traditional.navigation.vertical,
      background: FieldSenseColors["mlb-blue"],
      size: "15rem",
      // borderColor: TraditionalColors.white,
      leftLogo: {
        width: "10.7rem",
        height: "6.5rem",
        margin: "0 0 0 13px",
      },
      topBar: {
        color: TraditionalColors["mlb-red"],
        background: FieldSenseColors["toggle-not-selected"],
      },
      usernameDropdown: {
        color: TraditionalColors["mlb-navy"],
        background: FieldSenseColors["light-blue-grey"],
        hover: {
          color: FieldSenseColors["section-bg"],
          background: FieldSenseColors["light-blue-grey"],
        },
      },
      active: {
        color: FieldSenseColors["mlb-navy"],
        background: FieldSenseColors["light-blue-grey"],
      },
      hover: {
        color: TraditionalColors.white,
        background: TraditionalColors["mlb-secondary-blue"],
      },
      sublinks: {
        color: FieldSenseColors["mlb-navy"],
        background: FieldSenseColors["light-blue-grey"],
        hover: {
          color: FieldSenseColors["mlb-navy"],
          background: TraditionalColors.white,
        },
      },
    },
    horizontal: {
      ...Traditional.navigation.horizontal,
    },
  },
};

export default FieldSenseTheme;
