import { DesktopTabletView, MobileView } from "best-common-react";
import React from "react";
import styled from "styled-components";
import TopBarActions from "./TopBarActions";
import TopBarTitle from "./TopBarTitle";

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: calc((15rem - 45px) + 1.2rem);
  padding-right: 2.1rem;
  color: white;
  font-family: Roboto;
  font-size: 1.313rem;
  font-weight: 300;
`;

const MobileRowWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0.75rem;
  color: white;
  height: 2.813rem;
  background-color: ${(props) => props.theme.colors["toggle-not-selected"]};
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 1.2rem;
  font-weight: 300;
`;

const TopBarRow: React.FC = () => {
  return (
    <>
      <DesktopTabletView>
        <RowWrapper>
          <TopBarTitle />
          <TopBarActions />
        </RowWrapper>
      </DesktopTabletView>
      <MobileView>
        <MobileRowWrapper>
          <TopBarTitle />
          <TopBarActions />
        </MobileRowWrapper>
      </MobileView>
    </>
  );
};

export default TopBarRow;
