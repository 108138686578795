import { AlertHolder, useAlerts } from "best-common-react";
import React from "react";
import styled from "styled-components";

const AlertWrapper = styled.div`
  > div {
    > div {
      display: table;
      left: 0px;
      right: 0px;
      margin: 0 auto;
      width: fit-content;
      white-space: nowrap;
      > div {
        margin-left: 10px;
      }
    }
  }
`;

const Alerts: React.FC = () => {
  const { removeAlert, alerts } = useAlerts();

  return (
    <AlertWrapper id="fieldsense-alerts">
      <AlertHolder
        alerts={alerts}
        onRemove={(id: number | string): void => {
          if (id && removeAlert) {
            removeAlert(Number(id));
          }
        }}
      />
    </AlertWrapper>
  );
};

export default Alerts;
