import React from "react";
import { useLocation } from "react-router-dom";
import { useBallpark } from "../../contexts/BallparkContext";

const TopBarTitle: React.FC = () => {
  const { selectedBallpark } = useBallpark();
  const theLocation = useLocation();
  const currentLocation = theLocation.pathname;

  const convertRouteToTitle = (route: string) => {
    if (route.startsWith("/ballpark/")) {
      return `Ballpark - ${selectedBallpark ? selectedBallpark.name : ""}`;
    } else {
      return route
        .split("/")
        .slice(1)
        .map((part) => {
          return part.charAt(0).toUpperCase() + part.slice(1);
        })
        .join(" - ")
        .replace(/([0-9A-Z])/g, " $&")
        .trim();
    }
  };

  return <>{convertRouteToTitle(currentLocation)}</>;
};

export default TopBarTitle;
