import { Security } from "@okta/okta-react";
import { AlertProvider, BCR } from "best-common-react";
import React from "react";
import HttpInterceptor from "../api/HttpInterceptor";
import { PermissionsProvider } from "../contexts/PermissionsContext";
import { oktaAuth, UserProvider } from "../contexts/UserContext";
import FieldSenseTheme from "../theme/FieldSenseTheme";

const WrapperProvider: React.FC = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <UserProvider>
        <PermissionsProvider>
          <HttpInterceptor>
            <AlertProvider>
              <BCR theme={FieldSenseTheme}>{children}</BCR>
            </AlertProvider>
          </HttpInterceptor>
        </PermissionsProvider>
      </UserProvider>
    </Security>
  );
};

export default WrapperProvider;
