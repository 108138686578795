import React, { createContext, useContext, useEffect, useState } from "react";
import { Venue } from "../types/Venue";
import { usePermissions } from "./PermissionsContext";
import { useUser } from "./UserContext";

type BallparkContextType = {
  selectedBallpark: Venue;
  setSelectedBallpark: React.Dispatch<React.SetStateAction<Venue>>;
};

const BallparkContext = createContext<BallparkContextType | undefined>(undefined);

const BallparkProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [selectedBallpark, setSelectedBallpark] = useState<Venue>();
  const { userInfo } = useUser();
  const { isTech, hasMultipleBallparks, hasAnyBallparks } = usePermissions();

  useEffect(() => {
    if (!!userInfo && !selectedBallpark) {
      if (isTech && !hasMultipleBallparks() && hasAnyBallparks()) {
        setSelectedBallpark(userInfo.ballparks[0]);
      }
    }
  }, [userInfo, selectedBallpark, isTech, hasMultipleBallparks, hasAnyBallparks]);

  return (
    <BallparkContext.Provider
      value={{
        selectedBallpark,
        setSelectedBallpark,
      }}
    >
      {children}
    </BallparkContext.Provider>
  );
};

const useBallpark = (): BallparkContextType => {
  const ballparkContext = useContext<BallparkContextType>(BallparkContext);
  if (ballparkContext === undefined) {
    throw new Error("useBallpark must be used within a BallparkProvider");
  }
  return ballparkContext;
};

export { useBallpark, BallparkProvider };
