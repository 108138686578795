import axios from "axios";

const BASE_URL = process.env.SERVICES_URL;
const STATS_API_URL = process.env.STATS_API_URL;

const createAxiosInstance = (version: string) =>
  axios.create({
    baseURL: `${BASE_URL}/${version}`,
  });

export const fieldSenseConfigV1Client = createAxiosInstance("v1");

export const statsApiClient = axios.create({
  baseURL: `${STATS_API_URL}`,
});
