import { Button } from "best-common-react";
import React from "react";
import styled from "styled-components";
import { useForm } from "../../contexts/FormContext";
import { AddButton } from "../elements/CommonElements";

const ActionsWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 1.18rem;
`;

const FormButtonsWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

type HeaderButtonProps = {
  disabled?: boolean;
  leftMost?: boolean;
  variant: string;
};

export const ActionButton = styled(Button).attrs((props: HeaderButtonProps) => ({
  disabled: props.disabled ? props.disabled : false,
  size: "sm",
  variant: props.variant,
}))`
  margin-left: ${(props: HeaderButtonProps) => (props.leftMost ? 0 : "0.5rem")};
  max-height: 28px;
`;

const TopBarActions: React.FC = () => {
  const { isDirty, hasSaveAndCancel, hasAddButton, add, save, cancel } = useForm();

  return (
    <ActionsWrapper>
      {hasSaveAndCancel && isDirty ? (
        <FormButtonsWrapper>
          <ActionButton variant="primary" leftMost={true} onClick={save}>
            Save
          </ActionButton>
          <ActionButton variant="secondary" onClick={cancel}>
            Cancel
          </ActionButton>
        </FormButtonsWrapper>
      ) : null}
      {hasAddButton ? <AddButton onClick={add} /> : null}
    </ActionsWrapper>
  );
};

export default TopBarActions;
