import React, { createContext, useContext, useState } from "react";

export type RegisterConfigType = {
  addCallback: () => void;
  saveCallback: () => void;
  cancelCallback: () => void;
};

type FormContextType = {
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
  hasSaveAndCancel: boolean;
  setHasSaveAndCancel: (hasSaveAndCancel: boolean) => void;
  hasAddButton: boolean;
  setHasAddButton: (hasAddButton: boolean) => void;
  register: (config: RegisterConfigType) => void;
  add: () => void;
  save: () => void;
  cancel: () => void;
};

const FormContext = createContext<FormContextType | undefined>(undefined);

const FormProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const [hasSaveAndCancel, setHasSaveAndCancel] = useState<boolean>(false);
  const [hasAddButton, setHasAddButton] = useState<boolean>(false);

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [addCallback, setAddCallback] = useState<() => void>(() => {});
  const [saveCallback, setSavCallback] = useState<() => void>(() => {});
  const [cancelCallback, setCancelCallback] = useState<() => void>(() => {});

  const register = (registerConfig: RegisterConfigType) => {
    setAddCallback(() => registerConfig.addCallback);
    setSavCallback(() => registerConfig.saveCallback);
    setCancelCallback(() => registerConfig.cancelCallback);
  };

  const add = () => {
    addCallback();
  };

  const save = () => {
    saveCallback();
  };

  const cancel = () => {
    cancelCallback();
  };

  return (
    <FormContext.Provider
      value={{
        isDirty,
        setIsDirty,
        hasSaveAndCancel,
        setHasSaveAndCancel,
        hasAddButton,
        setHasAddButton,
        register,
        add,
        save,
        cancel,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

const useForm = (): FormContextType => {
  const formContext = useContext<FormContextType>(FormContext);
  if (formContext === undefined) {
    throw new Error("useForm must be used within a FormProvider");
  }
  return formContext;
};

export { useForm, FormProvider };
